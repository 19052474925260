import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@axios'
import config from '../../config'

let BASE_URL = config.BASE_URL;
const API_BASE_USERS = BASE_URL + '/users'

Vue.use(Vuex)

export default new Vuex.Store({
    namespace: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async fetchUsers() {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_BASE_USERS}/fetchUsers`)
                    .then((users) => {
                        this.commit('getUsers', users);
                        resolve(users)
                    })
                    .catch(error => reject(error))
            })
        },
        async fetchUsersByID(queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${API_BASE_USERS}/fetchUsersByID`, { params: queryParams })
                    .then((response) => {
                        this.commit('getUsersInfoByID', response);
                        resolve(response)
                    })
                    .catch((error) => {
                        debugger;
                        reject(error)
                    })
            })
        }
    },
    strict: process.env.DEV,
})
